<template>
  <div class="products-detail-han common-page">
    <Nav />
    <div class="page-top">
      <p class="small-title">{{ detail.sub_title }}</p>
      <h1 class="title">{{ detail.title }}</h1>
      <p class="tip">
        <span
          v-for="(tag, i) in detail.tags"
          :class="i < detail.tags.length - 1 && i > 0 ? 'middle-tip' : ''"
          :key="tag"
          >{{ tag }}</span
        >
      </p>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM16.3536 16.3536C16.4015 16.3056 16.4377 16.2504 16.4621 16.1914C16.4861 16.1333 16.4996 16.0697 16.5 16.003L16.5 16V15.9995V8C16.5 7.72386 16.2761 7.5 16 7.5C15.7239 7.5 15.5 7.72386 15.5 8V14.7929L8.35355 7.64645C8.15829 7.45118 7.84171 7.45118 7.64645 7.64645C7.45118 7.84171 7.45118 8.15829 7.64645 8.35355L14.7929 15.5H8C7.72386 15.5 7.5 15.7239 7.5 16C7.5 16.2761 7.72386 16.5 8 16.5H15.9995H16C16.008 16.5 16.016 16.4998 16.024 16.4994C16.0811 16.4967 16.1378 16.4842 16.1914 16.4621C16.2504 16.4377 16.3056 16.4015 16.3536 16.3536Z"
          fill="white"
          fill-opacity="0.7"
        />
      </svg>
    </div>
    <div class="common-card-wrap card-wrap-base">
      <div class="card-top v-html-content" v-html="detail.detail"></div>
    </div>
    <div class="common-card-wrap24 card-wrap-tab" v-if="tabList && tabList.length > 0 ">
      <div class="custom-vant-tab tab-wrap">
        <!-- <van-tabs type="card" v-model="activeName" >
          <van-tab v-for="(tab, eq) in tabList" :key="eq" :title="tab.name">
          </van-tab>
        </van-tabs> -->
        <van-tabs type="card" v-model="activeName" swipe-threshold="3">
          <van-tab :title="tab.name" v-for="tab in tabList" :key="tab.uuid">
            <div class="tab-cont info-cont">
              <van-swipe class="info-swipe" indicator-color="#FC0">
                <van-swipe-item
                  v-for="(item, index) in detail.tabs[tab.uuid]"
                  :key="index"
                >
                  <div class="circle-box" v-if="tab.type === 2">
                    <img :src="item.cover_image" />
                    <div class="about">
                      <div class="title">
                        {{ item.name }}
                      </div>
                      <div class="b-box">
                        <div class="left">
                          <div class="pdf-icon"></div>
                          <div class="size-text">{{ item.size }}</div>
                        </div>
                        <!-- <div class="down-icon" @click="openDownLoad(item)"></div> -->
                        <div class="down-icon">
                          <a
                            :href="item.download_link"
                            slot=""
                            ref="downloadLink"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M11.25 15.0398V11H12.75V15.0498L14.4795 13.32L15.54 14.3807L12.7121 17.209C12.3215 17.5997 11.6883 17.5997 11.2978 17.2091L8.47 14.3807L9.5305 13.32L11.25 15.0398ZM12.75 11H19.001C19.5509 11 20 11.4458 20 11.9958V19.0042C20 19.5511 19.5542 20 19.0042 20H4.99581C4.44892 20 4 19.5542 4 19.0042V11.9958C4 11.4489 4.44725 11 4.99896 11H11.25V4H12.75V11Z"
                                fill="white"
                              />
                            </svg>
                          </a>
                          <!-- <router-link :to="item.download_link">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M11.25 15.0398V11H12.75V15.0498L14.4795 13.32L15.54 14.3807L12.7121 17.209C12.3215 17.5997 11.6883 17.5997 11.2978 17.2091L8.47 14.3807L9.5305 13.32L11.25 15.0398ZM12.75 11H19.001C19.5509 11 20 11.4458 20 11.9958V19.0042C20 19.5511 19.5542 20 19.0042 20H4.99581C4.44892 20 4 19.5542 4 19.0042V11.9958C4 11.4489 4.44725 11 4.99896 11H11.25V4H12.75V11Z"
                                fill="white"
                              />
                            </svg>
                          </router-link> -->
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="circle-box circle-video"
                    v-else-if="tab.type === 1"
                  >
                    <div class="video-wrap">
                      <video
                        :poster="item.cover_image"
                        :src="item.download_link"
                        controls
                        :style="{
                          height: '100%',
                          width: '100%',
                          objectFit: 'cover',
                        }"
                      />
                    </div>

                    <div class="about">
                      <div class="title">
                        {{ item.name }}
                      </div>
                      <div class="b-box">
                        <div class="left">
                          <div class="mp4-icon"></div>
                          <div class="size-text">{{ item.size }}</div>
                        </div>
                        <div class="down-icon">
                          <!-- <router-link :to="item.download_link">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M11.25 15.0398V11H12.75V15.0498L14.4795 13.32L15.54 14.3807L12.7121 17.209C12.3215 17.5997 11.6883 17.5997 11.2978 17.2091L8.47 14.3807L9.5305 13.32L11.25 15.0398ZM12.75 11H19.001C19.5509 11 20 11.4458 20 11.9958V19.0042C20 19.5511 19.5542 20 19.0042 20H4.99581C4.44892 20 4 19.5542 4 19.0042V11.9958C4 11.4489 4.44725 11 4.99896 11H11.25V4H12.75V11Z"
                                fill="white"
                              />
                            </svg>
                          </router-link> -->
                          <a
                            :href="item.download_link"
                            slot=""
                            ref="downloadLink"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M11.25 15.0398V11H12.75V15.0498L14.4795 13.32L15.54 14.3807L12.7121 17.209C12.3215 17.5997 11.6883 17.5997 11.2978 17.2091L8.47 14.3807L9.5305 13.32L11.25 15.0398ZM12.75 11H19.001C19.5509 11 20 11.4458 20 11.9958V19.0042C20 19.5511 19.5542 20 19.0042 20H4.99581C4.44892 20 4 19.5542 4 19.0042V11.9958C4 11.4489 4.44725 11 4.99896 11H11.25V4H12.75V11Z"
                                fill="white"
                              />
                            </svg>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="circle-box circle-pop" v-else-if="tab.type === 3">
                    <img :src="item.cover_image" />
                    <div class="about">
                      <div class="title">
                        {{ item.name }}
                      </div>
                      <div class="b-box-center">
                        <div class="download-block" @click="openIframeForm(item)">
                          查看详情
                        </div>
                      </div>
                    </div>
                  </div>
                </van-swipe-item>
              </van-swipe>
            </div>
          </van-tab>
          <!-- <van-tab title="在线研讨会">
            <div class="tab-cont">
              
            </div>
          </van-tab> -->
        </van-tabs>
      </div>
    </div>
    <div
      class="common-card-wrap card-wrap-sample"
      v-if="detail.can_sample_apply || detail.advantages"
    >
      <div class="cont-wrap">
        <h1 class="title" v-html="detail.title"></h1>
        <svg
          class="arrow"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM16.3536 16.3536C16.4015 16.3056 16.4377 16.2504 16.4621 16.1914C16.4861 16.1333 16.4996 16.0697 16.5 16.003L16.5 16V15.9995V8C16.5 7.72386 16.2761 7.5 16 7.5C15.7239 7.5 15.5 7.72386 15.5 8V14.7929L8.35355 7.64645C8.15829 7.45118 7.84171 7.45118 7.64645 7.64645C7.45118 7.84171 7.45118 8.15829 7.64645 8.35355L14.7929 15.5H8C7.72386 15.5 7.5 15.7239 7.5 16C7.5 16.2761 7.72386 16.5 8 16.5H15.9995H16C16.008 16.5 16.016 16.4998 16.024 16.4994C16.0811 16.4967 16.1378 16.4842 16.1914 16.4621C16.2504 16.4377 16.3056 16.4015 16.3536 16.3536Z"
            fill="#CDD7DE"
          />
        </svg>
        <div class="img-box" v-if="detail.can_sample_apply">
          <img :src="detail.sample_image" alt="" />
        </div>
        <div class="tip">
          <!-- {{ sampleData.tip }} -->
        </div>
        <div class="btn-wrap" v-if="detail.can_sample_apply">
            <div class="btn-sample" @click="openIframeForm(detail, 'sample')">
              样品申请 →
          </div>
        </div>

        <div class="quote">
          <div class="title">您的优势概览</div>
          <ul class="quote-cont">
            <li v-for="(text, eq) in detail.advantages" :key="eq">
              <span class="cube"></span>{{ text }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="common-card-wrap card-wrap-form">
      <h1 class="title">联络表格</h1>
      <p class="text">
        您想了解更多浩亭或浩亭的产品 或希望在我们的的网站上发表评论？联系我们。
      </p>
      <div class="custom-form">
        <div class="iframe-box">
          <div v-if="isLoading" class="laoding-box">
            <van-loading color="#fc0">加载中...</van-loading>
          </div>
          <iframe
            :src="formUrl"
            @load="onLoad"
            width="100%"
            height="100%"
          ></iframe>
        </div>
      </div>
    </div>
    <div class="empty"></div>
    <van-popup
      v-model="iframeLinkShow"
      :close-on-click-overlay="false"
      :closeable="true"
      @close="closeIframeForm"
    >
      <div class="pop-form">
        <div class="iframe-box">
          <div v-if="isLoading" class="laoding-box">
            <van-loading color="#fc0">加载中...</van-loading>
          </div>
          <iframe
            :src="iframeLink"
            @load="onLoad"
            width="100%"
            height="100%"
          ></iframe>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import Nav from "../../component/nav.vue";
import request from "../../utils/request";
import dayjs from "dayjs";
// import Copy from "../../component/copy.vue";
import {
  Tab,
  Tabs,
  Swipe,
  SwipeItem,
  Form,
  Field,
  RadioGroup,
  Radio,
  Button,
  Cascader,
  Popup,
  Loading,
} from "vant";

export default {
  name: "ProductsDetailHan",
  components: {
    Nav,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Form.name]: Form,
    [Field.name]: Field,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Button.name]: Button,
    [Cascader.name]: Cascader,
    [Popup.name]: Popup,
    [Loading.name]: Loading,
    // Copy,
  },
  data() {
    return {
      form: {
        company: "",
        surname: "",
        name: "",
        email: "",
        agree: false,
        regionName: "",
        regionId: "",
      },
      activeName: "",
      detail: {},
      detailTop: {},
      sampleData: {
        btnText: "样品申请 →",
        link: "/sampleForm?type=common",
      },
      tabList: [],
      infoList: [],
      iframeLinkShow: false,
      iframeLink: "",
      // videosData: {
      //   imgUrl: require("../../image/producrs-han-videos.png"),
      //   title: "浩亭 Han-Modular® 多米诺新一代模块化工业连接器 CN.mp4",
      //   size: "1MB",
      //   btnText: "",
      //   link: "",
      // },
      // drawingData: {
      //   imgUrl: require("../../image/products-han-drawing.png"),
      //   title: "Han-Modular多米诺模块3D图纸",
      //   size: "",
      //   tip: "图纸仅可在web端下载",
      //   btnText: "复制下载链接",
      //   link: "",
      // },
      show: false,
      options: [
        {
          text: "浙江省",
          value: "330000",
          children: [{ text: "杭州市", value: "330100" }],
        },
        {
          text: "江苏省",
          value: "320000",
          children: [{ text: "南京市", value: "320100" }],
        },
      ],
      rules: {
        firstName: [{ required: true, message: "请输入姓名" }],
        lastName: [{ required: true, message: "请输入姓氏" }],
        posts: [{ required: true, message: "请选择国家/地区" }],
        city: [{ required: true, message: "请输入市" }],
        regionName: [{ required: true, message: "请选择国家/地区" }],
        company: [{ required: true, message: "请输入公司名称" }],
        email: [
          { required: true, message: "请输入电子邮箱名称" },
          {
            pattern:
              /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/,
            message: "邮箱格式错误！",
          },
        ],
        mobile: [
          { required: true, message: "请填写电话号码", trigger: "blur" },
          {
            pattern: /^1[34578]\d{9}$/,
            message: "电话号码格式不正确",
            trigger: "blur",
          },
        ],
      },
      isLoading: true,
      formUrl:
        "https://b2b.harting.com.cn/b2bchat/next_cockpit_harting/popup.html?lang=CN-ZH-HANS&cat=CN_Chat",
    };
  },
  methods: {
    onSubmit(form) {
      if (!form.agree) {
        this.$notify({
          message: "请勾选政策",
          color: "#000",
          background: "#fc0",
        });
      }
    },
    onFinish({ selectedOptions }) {
      this.show = false;
      this.form.regionName = selectedOptions
        .map((option) => option.text)
        .join("/");
    },
    formatDate(date) {
      return dayjs(date).format("YYYY.MM.DD HH:mm");
    },
    // 获取新闻列表数据
    fetchInfo(id) {
      request
        .get("/product/" + id) // 假设接口地址为 /news
        .then((response) => {
          this.detail = {
            ...response,
            tags: response.tags.filter((t) => !!t),
            advantages: response.advantages?.split("\n").filter((i) => !!i),
          }; // 将接口返回的数据赋值给newsList
          this.fetchAllTab();
        })
        .catch((error) => {
          console.error("Error fetching news:", error);
        });
    },
    fetchAllTab() {
      request.get("product_tab/all").then((res) => {
        if (res.data && this.detail.tabs) {
          const keys = Object.keys(this.detail.tabs);
          res.data.forEach((tab) => {
            if (keys.includes(tab.uuid)) {
              this.tabList.push({
                name: tab.name,
                uuid: tab.uuid,
                type: tab.type,
              });
            }
          });
        }
      });
    },
    onLoad() {
      this.isLoading = false;
    },
    openIframeForm(item, type = 'tab') {
      // this.iframeLinkShow = true;
      // if(type === 'tab') {
      //   this.iframeLink = item.download_link;
      // } else {
      //   this.iframeLink = item.form_url;
      // }
      // 直接跳动第三方
      if(type === 'tab') {
        this.iframeLink = item.download_link;
      } else {
        this.iframeLink = item.sample_form;
      }
      window.location.href = this.iframeLink
    },
    closeIframeForm() {
      this.isLoading = true;
    },
  },
  created() {
    const id = this.$route.query.id;
    // 在组件创建时调用fetchNewsList方法获取新闻列表数据
    this.fetchInfo(id);
    // this.fetchList(id)
  },
};
</script>
<style lang="less">
.products-detail-han {
  position: relative;
  .page-top {
    padding: 96px 32px 32px 32px;
    .small-title {
      margin-bottom: 8px;
      color: #000;
      font-size: 18px;
      font-style: normal;
      font-weight: 300;
      line-height: 100%; /* 18px */
    }
    .title {
      margin-bottom: 8px;
      color: #212027;
      font-size: 40px;
      font-style: normal;
      font-weight: 800;
      line-height: 120%; /* 48px */
    }
    .title::after {
      display: inline-block;
      width: 1px;
      height: 14px;
      background: rgba(255, 255, 255, 0.5);
    }
    .tip {
      color: #505054;
      font-family: "PingFang SC";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%; /* 22.4px */
      letter-spacing: -0.28px;
      .middle-tip {
        margin-bottom: 8px;
        position: relative;
        display: inline-block;
        padding: 0 20px;
      }
      .middle-tip::after {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-7px);
        content: "";
        display: inline-block;
        width: 1px;
        height: 14px;
        background: rgba(255, 255, 255, 0.8);
      }
      .middle-tip::before {
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translateY(-7px);
        content: "";
        display: inline-block;
        width: 1px;
        height: 14px;
        background: rgba(255, 255, 255, 0.8);
      }
    }
  }
  .card-top {
    padding-bottom: 40px;
    // .img-box {
    //   display: flex;
    //   justify-content: center;
    //   width: 100%;
    //   height: 290px;
    //   img {
    //     width: 220px;
    //     height: 290px;
    //     object-fit: contain;
    //   }
    // }
    // .tip {
    //   color: #212027;
    //   font-size: 14px;
    //   font-style: normal;
    //   font-weight: 300;
    //   line-height: 150%; /* 21px */
    // }
  }
  .card-wrap-tab {
    position: relative;
    top: -40px;
    padding-bottom: 80px;
    .tab-cont {
      padding: 32px 0 0 0;
    }
    .info-swipe {
      padding: 0 0 70px 0;
      .van-swipe-item {
        display: flex;
        justify-content: center;
      }
      .circle-box {
        width: 260px;
        border-radius: 24px;
        overflow: hidden;
        background: #fff;
        box-shadow: 0px 0px 16px 0px #d7d9dc;
      }
      img {
        display: block;
        width: 260px;
        height: 240px;
        object-fit: cover;
      }
    }
    .about {
      padding: 16px;
    }
    .title {
      margin-bottom: 8px;
      color: #212027;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%; /* 19.2px */
    }
    .b-box {
      display: flex;
      justify-content: space-between;
    }
    .b-box-center {
      display: flex;
      justify-content: center;
    }
    .left {
      display: flex;
      align-items: center;
    }
    .pdf-icon {
      margin-right: 4px;
      width: 20px;
      height: 20px;
      background: url("../../image/pdf-icon.png") no-repeat center center /
        cover;
    }
    .mp4-icon {
      margin-right: 4px;
      width: 20px;
      height: 20px;
      background: url("../../image/mp4-icon.png") no-repeat center center /
        cover;
    }
    .size-text,
    .tip {
      color: #a9a9ac;
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: 100%; /* 14px */
    }
    .tip {
      margin-bottom: 26px;
    }
    .btn-wrap {
      display: flex;
      justify-content: center;
      .btn-text {
        padding: 12px 24px;
        border-radius: 40px;
        background: @theme-color;
        color: #212027;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%; /* 19.2px */
      }
    }
    .down-icon {
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        background: @theme-color;
        border-radius: 50%;
      }
    }
    .download-block {
      // width: 32px;
      // height: 32px;
      // background: url("../../image/download-icon.png") no-repeat center center /
      // contain;
      font-size: 16px;
      line-height: 16px;
      font-weight: 600;
      border-radius: 40px;
      padding: 11px 24px 11px 24px;
      color: #212027;
      background: #ffcc00;
    }
    .circle-video {
      // width: 240px;
      // border-radius: 24px;
      // overflow: hidden;
      // background: #fff;
      // box-shadow: 0px 0px 16px 0px #d7d9dc;
      // img {
      //   display: flex;
      //   width: 100%;
      //   height: 300px;
      //   object-fit: cover;
      // }
      .video-wrap {
        width: 260px;
        height: 240px;
      }
    }
    .circle-pop {
      .title {
        margin-bottom: 10px;
      }
    }
    .circle-form {
      // border-radius: 24px;
      // padding: 40px 32px;
      // background: @theme-color;
      .btn-wrap {
        // position: relative;
        bottom: 40px;
        // padding: 0;
      }
      .custom-form {
        padding-bottom: 80px;
      }
    }
  }
  .card-wrap-sample {
    position: relative;
    top: -80px;
    .cont-wrap {
      padding-bottom: 40px;
    }
    .title {
      margin-bottom: 8px;
      color: #212027;
      font-size: 40px;
      font-style: normal;
      font-weight: 800;
      line-height: 120%; /* 48px */
    }
    .arrow {
      margin-bottom: 24px;
    }
    .img-box {
      display: flex;
      justify-content: center;
      width: 100%;
      img {
        display: block;
        width: 100%;
        height: 240px;
        object-fit: contain;
      }
    }
    .tip {
      margin-bottom: 24px;
      color: #212027;
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: 150%; /* 21px */
    }
    .btn-wrap {
      margin-bottom: 24px;
      display: flex;
    }
    .btn-sample {
      box-sizing: border-box;
      width: auto;
      height: 40px;
      padding: 12px 24px;
      border-radius: 40px;
      background: @theme-color;
      font-size: 14px;
      line-height: 100%;
      color: #212027;
    }
    .quote {
      margin-bottom: 24px;
      padding: 16px;
      border-radius: 16px;
      background: #f0f3f4;
      .title {
        margin-bottom: 16px;
        color: #212027;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%; /* 21.6px */
      }
      .quote-cont {
        li {
          padding-left: 4px;
          margin-bottom: 16px;
          color: #212027;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 22.5px */
          .cube {
            margin-right: 6px;
            display: inline-block;
            vertical-align: middle;
            width: 4px;
            height: 4px;
            border-radius: 1px;
            background: @theme-color;
          }
        }
      }
    }
  }
  .card-wrap-form {
    position: relative;
    top: -120px;
    // background: @theme-color;
    background: #f0f3f4;
    .custom-form {
      padding: 0;
    }
    .title {
      margin-bottom: 16px;
      color: #212027;
      font-size: 40px;
      font-style: normal;
      font-weight: 800;
      line-height: 120%; /* 48px */
    }
    .text {
      margin-bottom: 16px;
      color: #505054;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%; /* 22.4px */
    }
    .custom-form {
      .btn-wrap {
        position: relative;
        bottom: 0;
        padding: 0;
      }
    }
  }

  .empty {
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    height: 130px;
    // background: @theme-color;
    background: #f0f3f4;
  }
  .iframe-box {
    position: relative;
    width: 100%;
    height: 700px;
    .laoding-box {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%; -50%);
    }
  }
  .pop-form {
    width: 80vw;
    height: 80vh;
    .iframe-box {
      position: relative;
      width: 100%;
      height: 700px;
    }
  }
}
</style>
<style lang="less">
.products-detail-han {
  .card-wrap-tab {
    .van-tabs--card > .van-tabs__wrap {
      padding: 0;
      justify-content: center;
    }
  }
  .van-swipe__indicator {
    background: #777a7b;
  }
  .v-html-content{
    p{
      display: flex;
    }
    a{
      position: relative;
      display: inline-block;
      // word-wrap: break-word; /* 或者使用 overflow-wrap: break-word; */  
      // white-space: normal; /* 确保空白符被正确处理 */  
      padding: 12px 44px 12px 12px;
      background: #ffcc00;
      font-size: 16px;
      font-weight: 600;
      border-radius:  20px;
      white-space: nowrap; /* 防止内容换行 */  
      overflow: hidden; /* 隐藏溢出的内容 */  
      text-overflow: ellipsis; /* 超出部分显示为省略号 */  
    }
    a:after{
      position: absolute;
      right: 12px;
      top: 50%;
      transform: translateY(-50%);
      font-weight: 600;
      content: '→';
    }
  }
}
</style>
